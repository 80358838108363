<template>
<div class="lineModal">
  <a-modal
      :visible="visible"
      :footer="null"
      :width="1200"
      :mask="false"
      :centered="true"
      :closable="false"
      @cancel="cancelOk"
    >
      <a-spin :spinning="loading">
        <div class="modal_heard">
          <div class="heard_title"><a-icon class="icon" type="phone"/> <span>服务热线</span></div>
          <div class="back" @click="cancelOk"><a-icon class="back_icon" type="rollback" /><span>返回</span></div>
        </div>
        <div v-html="$xss(data)"></div>
      </a-spin>
  </a-modal>
</div>
</template>

<script>
import { getAction } from '@/api/manage'

export default {
  data() {
    return {
      visible: false,
      loading: false,
      data:{}
    }
  },
  created() {
    this.loadHotLine()
  },
  methods: {
    open() {
      this.visible = true
    },
    loadHotLine() {
      this.loading = true
      getAction('/mesnac/zmzywfw/zmzHelptell/list').then(res => {
        if(res.success) {
          this.data = res.result.records[0].remark
          this.loading = false
        }
      })
    },
    cancelOk() {
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
.ant-modal-root{
  /deep/.ant-modal-body{
    padding: none;
  }
}
 .modal_heard{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .heard_title{
      font-size: 22px;
      color: #101010;
      font-weight: 600;
      margin-left: 10px;
    }
    .back{
      cursor: pointer;
      font-size: 22px;
      color: #101010;
      margin-right: 10px;
      font-weight: 600;
      .back_icon{
        margin-right: 10px;
      }
    }
 }
</style>


