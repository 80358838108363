<template>
<a-config-provider :locale="locale">
  <div id="app">
    <router-view />
  </div>
</a-config-provider>
</template>

<script>
import Vue from 'vue'
import { ACCESS_TOKEN, REMEMBER_ME } from '@/store/mutation-types'
import locale from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  name: 'App',
  data() {
    return {
      locale
    }
  },
mounted() {
    let rememberMe =  Vue.ls.get(REMEMBER_ME)
    console.log('rememberMe: ', rememberMe);
    let beginTime = 0; //开始时间
    let differTime = 0; //时间差
    window.onunload = function () {
      differTime = new Date().getTime() - beginTime;
      console.log('differTime: ', differTime);
      if (differTime <= 5) {
        console.log("这是关闭");
        if(rememberMe) {
          this.$router.push({ path: "/login" });
        } else {
          Vue.ls.remove(ACCESS_TOKEN)
          //退出登录跳到首页
          this.$router.push({ path: "/login" });
        }
      } else {
        console.log("这是刷新");
      }
    };
    window.onbeforeunload = function () {
      beginTime = new Date().getTime();
    };
  },
}
</script>

<style lang="less">
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
img {
    content: normal !important;
}
.content{
  width: 1500px;
  margin: 0 auto;
}
</style>
