import api from './index'
import { axios } from '@/utils/request'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return axios({
    url: '/mesnac/sys/login',
    method: 'post',
    data: parameter
  })
}

export function loginByToken(token) {
  return axios({
    url: '/mesnac/sys/getUserInfoByToken',
    method: 'post',
    headers:{
      "X-Access-Token":token
    }
  })
}

export function phoneLogin(parameter) {
  return axios({
    url: '/mesnac/sys/phoneLogin',
    method: 'post',
    data: parameter
  })
}

export function getSmsCaptcha(parameter) {
  return axios({
    url: api.SendSms,
    method: 'post',
    data: parameter
  })
}
export function queryPermissionsByUser (params) {
  return axios({
    url:'/mesnac/sys/permission/getUserPermissionByToken',
    method:'get',
    data: params
  })
}
export function searchByKeywords (params) {
  console.log('params: ', params);
  return axios({
    url:'/mesnac/sys/sysDepart/searchBy',
    method:'get',
    data: params
  })
}
export function queryDepartTreeList (params) {
  return axios({
    url:'/mesnac/sys/sysDepart/queryTreeList',
    method:'get',
    data: params
  })
}
// 重复校验
export function duplicateCheck (params) {
  return axios({
    url:'/mesnacsys/duplicate/check',
    method:'get',
    data: params
  })
}



// export function getInfo() {
//   return axios({
//     url: '/api/user/info',
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     }
//   })
// }

export function logout(logoutToken) {
  return axios({
    url: '/mesnac/sys/logout',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'X-Access-Token':  logoutToken
    }
  })
}

/**
 * 第三方登录
 * @param token
 * @param thirdType
 * @returns {*}
 */
export function thirdLogin(token,thirdType) {
  return axios({
    url: `/mesnac/sys/thirdLogin/getLoginUser/${token}/${thirdType}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}