import axios from 'axios'
import { VueAxios } from './axios'
import { notification } from 'ant-design-vue'

// 创建 axios 实例
const service = axios.create({
  baseURL: window._CONFIG['domianURL'] // api base_url
  // timeout: 6000 // 请求超时时间
})

// request interceptor
service.interceptors.request.use(config => {
  if (config.method === 'get') {
    if (config.url.indexOf('sys/dict/getDictItems') < 0) {
      config.params = {
        _t: Date.parse(new Date()) / 1000,
        ...config.params
      }
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use(response => {
  return response.data
}, error => {
  console.log('Res error: ', error)
  if (error.response) {
    const { data, status } = error.response
    switch (status) {
      case 400:
        notification.error({ message: '系统提示', description: '错误请求', duration: 4 })
        break
      case 401:
        notification.error({ message: '系统提示', description: '未授权，请重新登录', duration: 4 })
        break
      case 403:
        notification.error({ message: '系统提示', description: '拒绝访问', duration: 4 })
        break
      case 404:
        notification.error({ message: '系统提示', description: '很抱歉，资源未找到!', duration: 4 })
        break
      case 500:
        notification.error({ message: '系统提示', description: '服务器内部错误：' + data.message, duration: 4 })
        break
      case 502:
        notification.error({ message: '系统提示', description: '502 Bad Gateway', duration: 4 })
        break
      case 504:
        notification.error({ message: '系统提示', description: '网络超时' })
        break
      default:
        notification.error({
          message: '系统提示',
          description: data.message || '请求数据异常',
          duration: 4
        })
        break
    }
  } else {
    if (error.message === 'Network Error') {
      notification.error({ message: '系统提示', description: '网络错误' })
    } else {
      notification.error({ message: '系统异常', description: error.message })
    }
  }
  return Promise.reject(error)
})

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}

export {
  installer as VueAxios,
  service as axios
}
