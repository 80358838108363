
export const MESNAC_SYSTEM_OPS_URL = {
  // 知识库域名Url
  mesnacSystemOpsUrl : `${window._CONFIG['adminURL']}` + ':9899',

  // 获取用户的流程数量
  getQuestionsCountInfoByUser: '/mesnac/systemOps/soSystemOpsQuestion/getQuestionsCountInfoByUser',
  // 获取-提交问题流程地址信息
  getQuestionBpmUrlInfo: '/mesnac/systemOps/soSystemOpsQuestion/getQuestionBpmUrlInfo',
  // 获取-用户提交流程分页信息
  queryUserSubmitQuestionsPage: '/mesnac/systemOps/soSystemOpsQuestion/queryUserSubmitQuestionsPage',
  // 获取-用户待审核流程分页信息
  queryUserNeedAuditQuestionsPage: '/mesnac/systemOps/soSystemOpsQuestion/queryUserNeedAuditQuestionsPage',

  // 获取-系统类型-字典
  getSoSystemTypeDictItems: '/mesnac/sys/dict/getDictItems/so_system_type',
  // 获取有效调查问卷列表
  listAllValidQuestionnaire: '/mesnac/systemOps/soQuestionnaire/listAllValidQuestionnaire',

  // 运维服务公告
  zmaPrescenterList: '/mesnac/zmap/zmaPrescenter/list',

  // 服务热线
  helpTellList: '/mesnac/zmzywfw/zmzHelptell/list',

  // 文件上传接口路径
  fileUploadUrl: `${window._CONFIG['domianURL']}` + 'mesnac/sys/common/upload',
  // 文件访问路径
  fileViewUrl: `${window._CONFIG['domianURL']}` + 'mesnac/sys/common/static/',
  // 新增投诉建议
  addSuggestion: '/mesnac/systemOps/soSystemOpsSuggestion/add',
  // 投诉建议详情
  querySuggestionById: '/mesnac/systemOps/soSystemOpsSuggestion/queryById',
  // 投诉建议列表
  querySuggestionList: '/mesnac/systemOps/soSystemOpsSuggestion/list',
  // 投诉建议数量
  getSuggestionCount: '/mesnac/systemOps/soSystemOpsSuggestion/getCountByUserAndProcessStatus',

  // 验证用户是否已答调查问卷
  checkQuestionnaireUserInstanceExist: '/mesnac/systemOps/soQuestionnaireUserInstance/checkQuestionnaireUserInstanceExist',
  // 获取调查问卷详情
  getQuestionnaireInfoById: '/mesnac/systemOps/soQuestionnaire/getQuestionnaireInfoById',
  // 提交用户调查问卷
  submitQuestionnaireUserAnswerInfo: '/mesnac/systemOps/soQuestionnaireUserInstance/submitQuestionnaireUserAnswerInfo',
}
