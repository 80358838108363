<template>
  <div class="layout_blank">
    <div class="head-top">
      <div class="top-warp">
        <div class="content">
          <div class="logo">
            <img src="~@/assets/img/log-up-w.png" alt="">
          </div>
          <div class="search-warp">
            <a-input-search class="search" placeholder="请输入搜索内容" enter-button @search="onSearch" />
          </div>
        </div>
      </div>
      <div class="top-nav">
        <div class="content">
          <div class="nav-menu">
            <a-menu :selectedKeys="[this.$route.path]" mode="horizontal">
              <a-menu-item key="/home"><router-link :to="{path:'/home'}">公司首页</router-link></a-menu-item>
              <a-menu-item key="/center"><router-link :to="{path: '/center'}">个人中心</router-link></a-menu-item>
              <a-menu-item key="/procedure"><router-link :to="{path:'/procedure'}">流程中心</router-link></a-menu-item>
              <a-menu-item key="/application"><router-link :to="{path: '/application'}">应用中心</router-link></a-menu-item>
              <a-menu-item key="/resources"><router-link :to="{path: '/resources'}">资源中心</router-link></a-menu-item>
              <a-menu-item key="/hotLine"><router-link :to="{path: '/hotLine'}">IT运维中心</router-link></a-menu-item>
            </a-menu>
          </div>
          <div class="user-warp">
            <div class="time-warp">
              <span class="time">{{ nowtime | updatetime }}</span>
            </div>
            <div class="user-info">
              <span style="border-bottom: 1px solid #fff;"> <a-icon class="user-icon" type="user"></a-icon>{{userInfo.realname}}，欢迎你</span>
              <div class="sign_out">
                <span @click="$router.push({path:'user'})">个人信息</span>
                <span @click="changePwd">修改密码</span>
                <span @click="logOut">退出登录</span>
              </div>
            </div>
            <div class="user-btn">
              <a-button class="btn" style="margin-right: 5px;" icon="solution" @click="$router.push({path:'mail'})" >通讯录</a-button>
              <a-button class="btn" icon="desktop" @click="portalJump">门户管理</a-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <div class="footer_bootom">
      <div class="content">
        <div class="footer_logo">
          <img src="~@/assets/img/down.png" alt="">
        </div>
        <div class="copyright">
          <span class="hotline" @click="jbpt"> <a-icon class="icon" type="phone"/> <span>举报平台</span></span>
          <span class="hotline" @click="openpage"> <a-icon class="icon" type="phone"/> <span>服务热线</span></span>
          <span>版权所有:</span> <span style="margin-left:0.5vw;">软控股份有限公司</span>
        </div>
      </div>
    </div>
    <div class="serviceHotline" @click="openpage">
      <img class="img" src="~@/assets/img/hotLine.png" alt="">
      <!-- <a-icon class="icon" type="phone"/> -->
    </div>
    <!-- 修改密码框 -->
    <a-modal
      :width="600"
      :visible="visible"
      title="修改密码"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <template slot="footer" class="footer_btn">
        <a-button @click="handleCancel">取消</a-button>
        <a-button class="okBtn" @click="handleOk">确定</a-button>
      </template>
      <a-spin :spinning="confirmLoading">
        <a-form :form="form">
          <a-form-item label="旧密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input-password  v-decorator="['password',validatorRules.password]" placeholder="请输入旧密码" ></a-input-password>
          </a-form-item>
          <a-form-item label="新密码" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input-password  v-decorator="['pwd',validatorRules.pwd]" placeholder="请输入新密码" ></a-input-password>
          </a-form-item>
          <a-form-item label="用户名" :labelCol="labelCol" :wrapperCol="wrapperCol">
            <a-input disabled  v-decorator="['username', {initialValue:userInfo.username }]" placeholder="请输入用户名" ></a-input>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
    <server-line ref="lineForm" />
  </div>
</template>

<script>
    import moment from 'moment'
    import Vue from 'vue'
    import {getAction} from '@/api/manage'
    import { USER_INFO, ACCESS_TOKEN } from "@/store/mutation-types"
    import { logout } from "@/api/login"
    import { MESNAC_SYSTEM_OPS_URL } from '../views/user/modules/config'
    import serverLine from '@/views/serverLine'


    export default {
        name: 'BlankLayout',
        filters: {
            updatetime: function (value) {
                return moment(value).format('YYYY年MM月DD日 HH:mm:ss')
            }
        },
        components: {serverLine},
        data() {
            return {
                url: MESNAC_SYSTEM_OPS_URL,
                adminURL: window._CONFIG['adminURL'],
                userInfo: Vue.ls.get(USER_INFO),
                token: Vue.ls.get(ACCESS_TOKEN),
                current: ['home'],
                nowtime: new Date(),
                visible: false,
                confirmLoading: false,
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 4 }
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 17 }
                },
                form: this.$form.createForm(this),
                validatorRules: {
                    password: { rules: [{ required: true, message: '请输入旧密码!' }] },
                    pwd: { rules: [{ required: true, message: '请输入新密码!' }] },
                },
            }
        },
        mounted() {
            let _this = this
            this.timer = setInterval(() => {
                _this.nowtime = new Date()
            }, 1000)
        },
        methods: {
            openpage(){
                this.$refs.lineForm.open()
            },
            jbpt(){
              window.open("http://jubao.mesnac.com")
            } ,
            onSearch(value) {
                console.log(value)
                let path = this.$route.path
                if(path == '/search') {
                    this.$router.push({ query:{ name:value }})
                } else {
                    this.$router.push({path:'search', query:{ name:value }})
                }
                console.log(path)
                // this.$router.push({path:'detailsList', query:{ name:value }})
            },
            portalJump() {
                var tempwindow=window.open('_blank');
                tempwindow.location=`${this.adminURL}:9001/user/login2?token=${this.token}`;
            },
            // 修改密码
            changePwd() {
                console.log(this.userInfo)
                this.visible = true
            },
            handleOk() {
                const that = this;
                this.form.validateFields((err, values) => {
                    if (!err) {
                        that.confirmLoading = true;
                        let formData = Object.assign(values);
                        console.log('formData: ', formData);
                        getAction('/mesnac/sys/updatePWD', formData).then(res => {
                            if(res.success) {
                                this.$message.success('修改密码成功,请重新登录!')
                                this.$router.push({path:'login'})
                            } else {
                                this.$message.warn(res.message)
                            }
                        })
                        console.log('formData: ', formData);
                    }
                })
            },
            handleCancel() {
                this.visible = false
            },
            // 退出登录
            logOut() {
                logout(this.token).then(res => {
                    if(res.success) {
                        this.$router.push({path:'login'})

                    } else {
                        this.$message.warn(res.message)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
  .layout_blank {
    width: 100%;
    // background: url("~@/assets/img/home.png") no-repeat top center / cover;
    // height: 100%;
    // background:  rgba(246, 246, 246, 100);
  }
  .okBtn {
    color: #fff;
    background: #be002e;
  }
  .head-top{
    width: 100%;
    // border-bottom: 1px solid rgba(187, 187, 187, 100);
    // background: #BC0039;
    .top-warp{
      width: 100%;
      height: 80px;
      line-height: 80px;
      background-color:#be002e;
      border-bottom: 1px solid rgba(239, 208, 219, 100);
      .content {
        display: flex;
        justify-content: space-between;
      }
      .logo{
        // padding-top: 10px;
        .logo-title{
          margin-left: 20px;
          font-size: 33px;
          color: rgba(246, 246, 246, 100);
          line-height: 20px;
          vertical-align: middle;
          font-weight: 800;
          font-family: FZZongYi-M05S;
        }
        img{
          width: 270px;
          height: 40% ;
          margin-top: 14px;
        }
      }
      .search-warp{
        line-height: 80px;
        .search{
          vertical-align: middle;
          width: 400px;
          border-radius: 5px;
          line-height: 80px;
          /deep/ .ant-input-wrapper{
            // max-height: 40px;
          }
          /deep/input::-webkit-input-placeholder{
            font-size: 16px;
            color: #000;
          }
          /deep/ .ant-input-search-button{
            width: 50px;
            background: #fff;
            color: #be002e;
            border: none;
            border-left: 1px solid #be002e;
            text-align: center;
            padding: 0px;
            .anticon{
              font-weight: 600;
              font-size: 20px;
            }
          }
        }
      }
    }
    .top-nav{
      height: 70px;
      background: #be002e;
      .content {
        display: flex;
        justify-content: space-between;
      }
      .nav-menu {
        height: 70px;
        .ant-menu {
          height: 70px;
          line-height: 70px;
          background: #be002e;
          color: #fff;
          border-bottom: none;
          .ant-menu-item{
            text-align: center;
            font-size: 22px;
            font-weight: 600;
            border-bottom: none;
            padding: 0 16px;
            a{
              color: #fff;
            }
            &:hover {
              border: none;
            }
          }
          .ant-menu-item-active {
            position: relative;
            color: #fff;
            // border-bottom: 2px solid #fff;
            font-size: 22px;
            font-weight: 600;
            a{
              color: #fff;
            }
            &::after{
              content: "";
              position: absolute;
              height: 3px;
              background: #fff;
              width: 80px;
              bottom: 1px;
              left: 0px;
              margin-left: 16%;
            }
          }
          .ant-menu-item-selected{
            color: #fff;
            // border-bottom: 2px solid #fff;
            border: none;
            font-size: 22px;
            font-weight: 600;
            a{
              color: #fff;
            }
            &::after{
              content: "";
              position: absolute;
              height: 3px;
              background: #fff;
              width: 80px;
              bottom: 1px;
              left: 0px;
              margin-left: 16%;
            }
          }
        }
      }
      .user-warp {
        display: flex;
        .time-warp{
          line-height: 70px;
          color: #fff;
          font-size: 20px;
        }
        .user-info{
          margin-left: 25px;
          margin-right: 20px;
          line-height: 70px;
          color: #fff;
          font-size: 20px;
          cursor: pointer;
          position: relative;
          .user-icon{
            margin-right: 5px;
            // border-bottom: 1px solid #fff;
            // background-color: #fff;
          }
          .sign_out{
            transition: all 0.3s;
            z-index: 110;
            position: absolute;
            bottom: -120px;
            width: 150px;
            background: #be002e;
            text-align: center;
            display: none;
            span{
              display: block;
              height: 40px;
              line-height: 40px;
            }
          }
          &:hover .sign_out {
            display: block;
          }
        }
        .user-btn{
          line-height: 70px;
          .btn{
            height: 30px;
            font-size: 18px;
            background-color:#be002e;
            color: rgba(255, 255, 255, 100);
            border-radius: 10px;
            border: 1px solid rgba(255, 255, 255, 100);
          }
        }
      }
    }
  }
  .footer_bootom{
    // margin-top: 40px;
    background: #fff;
    height: 80px;
    .content{
      display: flex;
      justify-content: space-between;
    }
    .footer_logo{
      width: 447px;
      margin-top: 20px;
      img{
        width: 100%;
        height: 60px;
      }
    }
    .copyright {
      line-height: 80px;
      color: #101010 10000%;
      font-size: 14px;
      .hotline{
        .icon{
          color: #F4A409;
          margin-right: 5px;
        }
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
  .serviceHotline {
    cursor: pointer;
    text-align: center;
    width: 70px;
    height: 70px;
    padding: 5px;
    background: #be002e;
    // background: #fff;
    position: fixed;
    bottom: 100px;
    right: 40px;
    // line-height: 60px;
    .img {
      width: 100%;
    }
    .icon{
      color: #fff;
      font-size: 30px;
    }
  }
</style>
