// import { Layout } from 'ant-design-vue'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from './BlankLayout'



// 公共路由
export const constantRoutes = [
//   {
//     path: '/redirect',
//     component: Layout,
//     hidden: true,
//     children: [
//       {
//         path: '/redirect/:path(.*)',
//         component: (resolve) => require(['@/views/redirect'], resolve)
//       }
//     ]
//   },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/alteration',
    name: 'alteration',
    component: (resolve) => require(['@/views/user/Alteration'], resolve),
    hidden: true
  },
  {
    path: '/serverLineOut',
    name: 'serverLineOut',
    component:(resolve) => require(['@/views/serverLineOut.vue'], resolve),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: '/home',
        component:(resolve) => require(['@/views/homePage/Home.vue'], resolve),
        hidden: true
      },
      {
        path: '/details',
        component:(resolve) => require(['@/views/homePage/ArticleDetails.vue'], resolve),
        hidden: true
      },
      {
        path: '/detailsList',
        component:(resolve) => require(['@/views/homePage/MoreDetailsList.vue'], resolve),
        hidden: true
      },
      {
        path: '/search',
        component:(resolve) => require(['@/views/homePage/Search.vue'], resolve),
        hidden: true
      },
      {
        path: '/procedure',
        component:(resolve) => require(['@/views/procedure/Procedure.vue'], resolve),
        hidden: true
      },
      {
        path: '/user',
        component:(resolve) => require(['@/views/user/User.vue'], resolve),
        hidden: true
      },
      {
        path: '/mail',
        component:(resolve) => require(['@/views/user/MailList.vue'], resolve),
        hidden: true
      },
      {
        path: '/hotLine',
        component:(resolve) => require(['@/views/user/modules/HotLine.vue'], resolve),
        hidden: true
      },
      {
        path: '/hotLineMyList',
        component:(resolve) => require(['@/views/user/modules/HotLineMyList.vue'], resolve),
        hidden: true
      },
      {
        path: '/questionnaireInfo',
        component:(resolve) => require(['@/views/user/modules/QuestionnaireInfo.vue'], resolve),
        hidden: true
      },
      {
        path: '/application',
        component:(resolve) => require(['@/views/application/Application.vue'], resolve),
        hidden: true
      },
      {
        path: '/schedule',
        component:(resolve) => require(['@/views/center/modules/Calendar.vue'], resolve),
        hidden: true,
      },
      {
        path: '/meeting',
        component:(resolve) => require(['@/views/center/modules/Meeting.vue'], resolve),
        hidden: true,
      },
      {
        path: '/MesnacMaterialList',
        component:(resolve) => require(['@/views/center/modules/MesnacMaterialList.vue'], resolve),
        hidden: true,
      },
      {
        path: '/center',
        component:(resolve) => require(['@/views/center/Center.vue'], resolve),
        hidden: true,
        // children:[
        //   {
        //     path: '/schedule',
        //     component:(resolve) => require(['@/views/center/modules/Calendar.vue'], resolve),
        //     hidden: true,
        //   }
        // ]
      },
      {
        path:'/resources',
        component:(resolve) => require(['@/views/resources/Resources.vue'], resolve),
        hidden: true,
      }
    ]
  }
//   {
//     path: '/404',
//     component: (resolve) => require(['@/views/error/404'], resolve),
//     hidden: true
//   },
//   {
//     path: '/401',
//     component: (resolve) => require(['@/views/error/401'], resolve),
//     hidden: true
//   },
//   {
//     path: '',
//     component: Layout,
//     redirect: 'index',
//     children: [
//       {
//         path: 'index',
//         component: (resolve) => require(['@/views/index'], resolve),
//         name: '首页',
//         meta: { title: '首页', icon: 'dashboard', noCache: true, affix: true }
//       }
//     ]
//   },
]

export default new Router({
  // mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})


