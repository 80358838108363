import Vue from 'vue'
import router from './router'
import { mapActions } from 'vuex'
import { getAction, getFileAccessHttpUrl } from '@/api/manage'
import { ACCESS_TOKEN, USER_INFO, H3, REMEMBER_ME } from '@/store/mutation-types'
import serverLine from '@/views/serverLine'
import { setTimeout } from 'timers';
import user from './store/modules/user'

router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/alteration' || to.path === '/serverLineOut') {
    next();
  } else {
    console.log(to.query.filepath)
    if(to.query.token&&to.query.token!==''){
      user.actions.LoginByToken(to.query.token).then((res) => {
        next({ path: '/home',query:{path:to.path.replace('/',''),filepath:to.query.filepath}})
      })
    }else{
      let token = Vue.ls.get(ACCESS_TOKEN)
      if (token === null || token === '') {
        next({ path: '/login'})
      } else {
        console.log('next')
        next();
      }
    }

  }
});

router.afterEach(() => {
   // finish progress bar
})
