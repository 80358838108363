import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import Storage from 'vue-ls'
import router from './router'
import store from './store'
import xss from 'xss'
import '@/permission'
import {
  ACCESS_TOKEN
} from "@/store/mutation-types"
import config from '@/defaultSettings'
import serverLine from './views/serverLine'
import {
  Button,
  Form,
  Input,
  InputNumber,
  Checkbox,
  Icon,
  Row,
  Col,
  Menu,
  Card,
  Tabs,
  Carousel,
  Table,
  notification,
  Modal,
  message,
  DatePicker,
  ConfigProvider,
  Spin,
  Pagination,
  Tree,
  Popconfirm,
  Calendar,
  Radio,
  Select,
  Divider,
  TreeSelect,
  Upload,
  Tooltip,
  List,
  Avatar,
  FormModel,
  badge
} from 'ant-design-vue'
// import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// import VueRouter from 'vue-router'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
//导入字体文件
import './assets/font/dsfont.css'

Vue.prototype.$video = Video
// Vue.use(Antd);
// Vue.use(VueXss)
Vue.use(Storage, config.storageOptions)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(Icon)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Card)
Vue.use(Tabs)
Vue.use(Carousel)
Vue.use(Table)
Vue.use(Modal)
Vue.use(DatePicker)
Vue.use(ConfigProvider)
Vue.use(Spin)
Vue.use(Pagination)
Vue.use(Tree)
Vue.use(Popconfirm)
Vue.use(Calendar)
Vue.use(Radio),
Vue.use(Select)
Vue.use(Divider)
Vue.use(TreeSelect)
Vue.use(Upload)
Vue.use(Tooltip)
Vue.use(List)
Vue.use(Avatar)
Vue.use(badge)
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$confirm = Modal.confirm

// Vue.use(VueRouter)

const whiteList = xss.getDefaultWhiteList()
Object.keys(whiteList).forEach(key => {
  whiteList[key].push('style')
  whiteList[key].push('align')
})
whiteList.video.push('poster')
whiteList.video.push('controlslist')
Object.assign(whiteList, {
  iframe: ['src', 'width', 'height', 'frameborder', 'scrolling', 'allowfullscreen'],
  figure: ['class', 'style'],
  figcaption: ['class', 'style'],
  source: ['src', 'type']
})
const myxss = new xss.FilterXSS({
  whiteList,
  css: false
})
Vue.prototype.$xss = html => myxss.process(html)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  components: {serverLine},
  mounted () {
    store.commit('SET_TOKEN', Vue.ls.get(ACCESS_TOKEN))
  },
  render: h => h(App),
}).$mount('#app')
